import { permissions } from '@mpk/shared/domain';

/**
 * Permission for the contracts
 */
enum ContractsPermissionsEnum {
  None = 0,
  /**
   * Contracts in contract management can be viewed
   */
  ViewContracts = 1,
}

export const ContractsPermissions = permissions(ContractsPermissionsEnum, {
  featureId: 'Contracts',
});
